import React from 'react';
import "./CTA.css";

const CTA = () => {
    return (
        <section id="cta-1693">
            <div className="cs-container">
                <div className="cs-content">
                    <h2 className="cs-title">Ready To Get The Site You Always Wanted?</h2>
                    <a href="/" className="cs-button-solid">Get Started Today</a>
                </div>
            </div>
            <div className="cs-wrapper">
                <img className="cs-graphic cs-graphic-1" src="./water1.jpeg" alt="logo" loading="lazy" decoding="async" />
                {/* <img className="cs-graphic cs-graphic-2" src="https://static.vecteezy.com/system/resources/previews/000/620/918/original/wave-beach-logo-and-symbols-vector-template.jpg" alt="logo" loading="lazy" decoding="async" /> */}
            </div>
        </section>                      
    );
};

export default CTA;