import React from 'react';
import "./Banner.css"
import ParticlesComponent from '../ParticlesComponent';

const Banner = ({ pathname }) => {
    const displayNames = {
        '/contact': 'Contact Us',
        '/about': 'About',
        '/faq': 'FAQ',
        '/services': 'Services',
        '/pricing': 'Pricing',
        '/privacy': 'Privacy Policy',
    };

    const displayName = displayNames[pathname] || pathname;

    return (
        <div id="banner-1519">
            <div className="cs-container">
                <span className="cs-int-title">{displayName.toUpperCase()}</span>
                {/* <div className="cs-breadcrumbs">
                    <a href="#" className="cs-link">Home</a>
                    <a href="#" className="cs-link cs-active">{displayName}</a>
                </div> */}
            </div>
            {/*Background Image*/}
            <picture className="cs-background">
                {/*Mobile Image*/}
                {/* <source media="(max-width: 600px)" srcSet="./blueGif.gif" /> */}
                {/*Tablet and above Image*/}
                {/* <source media="(min-width: 601px)" srcSet="./blueGif.gif" /> */}
                {/* <img 
                    decoding="async" 
                    src="./blueGif.gif" 
                    alt="people laughing" 
                    aria-hidden="true" 
                    style={{ 
                        top: "16vh",
                        width: '100%', 
                        height: '50%', 
                        objectFit: 'contain' // or 'contain' if you want to see the whole image
                    }} 
                /> */}
                <ParticlesComponent />
            </picture>
            <div className="cs-wrapper">
                {/* <img className="cs-graphic cs-graphic-1" src="./water1.jpeg" alt="logo" loading="lazy" decoding="async" /> */}
                {/* <img className="cs-graphic cs-graphic-2" src="https://static.vecteezy.com/system/resources/previews/000/620/918/original/wave-beach-logo-and-symbols-vector-template.jpg" alt="logo" loading="lazy" decoding="async" /> */}
            </div>
            {/* <svg className="cs-mask" xmlns="http://www.w3.org/2000/svg" width="1920" height="136" viewBox="0 0 1920 136" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M1920 136V0C1803.21 45.3334 1445.52 136 951 136C456.485 136 111.116 45.3334 0 0V136H951H1920Z" fill="var(--maskBG)"/>
            </svg> */}

        </div>
    );
};

export default Banner;