import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import "./Navbar.css";

const Navbar = () => {
    // const [isMenuActive, setMenuActive] = useState(false);
    // const [isBodyOpen, setBodyOpen] = useState(false);
    // const [isExpanded, setExpanded] = useState(false);
    const [isScroll, setScroll] = useState(false);
    // const [dropdownActive, setDropdownActive] = useState(false);

    const location = useLocation();  // Get current location

    // const toggleMenu = () => {
    //     setMenuActive(!isMenuActive);
    //     setBodyOpen(!isBodyOpen);
    //     setExpanded(!isExpanded);
    // };

    // const toggleDropdown = () => {
    //     setDropdownActive(!dropdownActive);
    // };

    useEffect(() => {
        const checkScroll = () => {
            setScroll(window.scrollY >= 100);
        };

        window.addEventListener('scroll', checkScroll);

        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    // Function to check if the link is active
    const isActive = (path) => location.pathname === path ? "cs-active" : "";

    return (
        <header id="cs-navigation" style={{ zIndex: "999" }} className={isScroll ? 'hi' : ''}>
            <div className="cs-container">
                {/*Nav Logo*/}
                <Link to="/" className="cs-logo">
                    <img src="./NewFontLakeGroveHorizLogoLight.svg" alt="logo" decoding="async" />
                </Link>
                {/*Navigation List*/}
                <nav className="cs-nav" role="navigation">
                    {/*Mobile Nav Toggle*/}
                    <button className="cs-toggle">
                        <div className="cs-box">
                            <span className="cs-line cs-line1"></span>
                            <span className="cs-line cs-line2"></span>
                            <span className="cs-line cs-line3"></span>
                        </div>
                    </button>
                    <div className="cs-ul-wrapper">
                        <ul id="cs-expanded" className="cs-ul">
                            <li className="cs-li">
                                <Link to="/" className={`cs-li-link ${isActive('/')}`}>
                                    Home
                                </Link>
                            </li>
                            {/* <li className="cs-li">
                                <Link to="/about" className={`cs-li-link ${isActive('/about')}`}>
                                    About
                                </Link>
                            </li> */}
                            <li className="cs-li">
                                <Link to="/pricing" className={`cs-li-link ${isActive('/pricing')}`}>
                                    Pricing
                                </Link>
                            </li>
                            {/* <li className="cs-li">
                                <Link to="/faq" className={`cs-li-link ${isActive('/faq')}`}>
                                    FAQ
                                </Link>
                            </li>
                            <li className="cs-li">
                                <Link to="/contact" className={`cs-li-link ${isActive('/contact')}`}>
                                    Contact
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                </nav>
                <div className="cs-contact-group"> 
                    <div className="cs-social">
                        <a href="/" className="cs-button-solid">GET STARTED</a>
                        {/* <a  className="cs-social-link">
                            <img className="cs-social-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/facebook-1a.svg" alt="facebook icon" width="12" height="12" aria-hidden="true" decoding="async" />
                        </a>
                        <a  className="cs-social-link">
                            <img className="cs-social-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/twitter-1a.svg" alt="twitter icon" width="12" height="12" aria-hidden="true" decoding="async" />
                        </a>
                        <a  className="cs-social-link">
                            <img className="cs-social-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/instagram1a.svg" alt="instagram icon" width="12" height="12" aria-hidden="true" decoding="async" />
                        </a>
                        <a  className="cs-social-link">
                            <img className="cs-social-icon" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/youtube1a.svg" alt="youtube icon" width="12" height="12" aria-hidden="true" decoding="async" />
                        </a> */}
                    </div>
                </div> 
            </div>
        </header>
    );
};

export default Navbar;
