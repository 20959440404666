import React from 'react';
import "./Pricing.css"; // Ensure this includes the styles you want for the layout/colors.

const PricingPlan = ({ planName, price, priceSubtitle, isPopular, features }) => (
    <li className={`cs-item ${isPopular ? 'cs-popular' : ''}`}>
        <span className="cs-package">{planName}</span>
        <ul className="cs-ul">
            {features.map((feature, index) => (
                <li key={index} className={`cs-li ${feature.disabled ? 'cs-disabled' : ''}`}>
                    {feature.name}
                    <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async"
                        src={feature.disabled 
                            ? "https://oakharborwebdesigns.com/assets/images/nope.svg"  // X icon URL
                            : "https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/enterprise-tick.svg"  // Checkmark URL
                        }
                        alt={feature.disabled ? "crossmark" : "checkmark"}
                        style={{
                            width: feature.disabled ? "18px" : "18px",
                            height: feature.disabled ? "18px" : "18px",
                            opacity: feature.disabled ? "0.5" : "1"
                        }}
                    />
                </li>
            ))}
        </ul>
        <span className="cs-price">${price}
            <span className="cs-duration">{priceSubtitle}</span>
        </span>
        <a href="/" className="cs-link">
            <span className={`cs-button-solid ${isPopular ? 'popular' : 'popular not'}`}>Get Started</span>
            <img className="cs-arrow" loading="lazy" decoding="async"
                src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images/Icons/enterprise-arrow-right.svg"
                alt="icon" width="20" height="20" aria-hidden="true" />
        </a>
    </li>
);

const Pricing = () => {
    const plans = [
        {
            planName: 'Lump Sum',
            price: "3500",
            priceSubtitle: '+$25/mo Hosting',
            isPopular: false,
            features: [
                { name: 'Design And Development', disabled: false },
                { name: '$25/mo Hosting', disabled: false },
                { name: '$100 fee Per Page after 5', disabled: false },
                { name: '+$50/mo Unlimited Edits Add-on', disabled: false },
                { name: '+$250 To Add A Blog', disabled: false },
                { name: '24/7 Support', disabled: true },
                { name: 'Lifetime Updates', disabled: true },
            ],
        },
        {
            planName: 'Monthly',
            price: '175',
            priceSubtitle: 'Per Month',
            isPopular: true,  // Highlight the middle plan
            features: [
                { name: 'Design And Development', disabled: false },
                { name: 'Includes Hosting', disabled: false },
                { name: '$100 fee Per Page after 5', disabled: false },
                { name: '+$250 To Add A Blog', disabled: false },
                { name: 'Unlimited Edits', disabled: false },
                { name: '24/7 Support', disabled: false },
                { name: 'Lifetime Updates', disabled: false },
            ],
        },
        {
            planName: 'ECommerce',
            price: '8000',
            priceSubtitle: 'Starting',
            isPopular: false,
            features: [
                { name: 'Custom Shopify Store', disabled: false },
                { name: 'Configure Any And All Apps', disabled: false },
                { name: 'Integrated Shipping', disabled: false },
                { name: 'Shopify Tutorial Walkthrough', disabled: false },
                { name: 'Fully Editable In Shopify CMS', disabled: false },
                { name: '+$50/mo Unlimited Edits', disabled: true },
                { name: '24/7 Support', disabled: true },
            ],
        },
    ];

    return (
        <section id="pricing-1690">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">Our Pricing</span>
                    <h2 className="cs-title">Pricing Packages For Every Budget</h2>
                </div>
                <ul className="cs-card-group">
                    {plans.map((plan, index) => (
                        <PricingPlan key={index} {...plan} />
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Pricing;
