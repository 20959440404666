import React from 'react';
import Hero from "./Hero/Hero";
import Services from "./Services/Services";
import SideBySide from "./SideBySide/SideBySide";
// import Portfolio from "./Portfolio/Portfolio";
// import Performance from "./Performance/Performance";
import Pricing from "../Pricing/Pricing";
import CTA from "./CTA/CTA";

import "./Home.css"

const Home = () => {
    return (
        <>
        <Hero />
        <Services />
        <SideBySide />
        {/* <Portfolio /> */}
        {/* <Performance /> */}
        <Pricing />
        <CTA />
        </>
    );
};

export default Home;