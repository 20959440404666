import React from 'react';
import "./Hero.css"

const Hero = () => {
    return (
        <section id="hero-358">
            <div className="cs-container">
                {/* <img className="cs-graphic cs-graphic-1" src="./water1.jpeg" alt="logo" loading="lazy" decoding="async" /> */}
                    {/* <img className="cs-graphic cs-graphic-2" src="https://static.vecteezy.com/system/resources/previews/000/620/918/original/wave-beach-logo-and-symbols-vector-template.jpg" alt="logo" loading="lazy" decoding="async" /> */}
                <div className="cs-flex-group">
                    <span className="cs-topper">Custom Designs, Custom Coded</span>
                    <h1 className="cs-title">SMALL BUSINESS WEB DESIGNER</h1>
                    <p className="cs-text">
                    No page builders or WordPress. We offer 100% hand-coded websites with superior results starting at $175/mo.
                    </p>
                    <div className="cs-button-group">
                        <a href="/" className="cs-button-solid">GET STARTED</a>
                        <a href="/" className="cs-button-solid-transparent">ABOUT US</a>
                            {/* <picture className="cs-picture">
                                <img className="cs-img" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Hero/phone-white.svg" alt="icon" width="24" height="24" aria-hidden="true" />
                            </picture> */}
                            {/* <div className="cs-content">
                                <span className="cs-tag">Call Anytime</span>
                                <span className="cs-number">(+123) 5462 3257</span>
                            </div> */}
                    </div>
                </div>
                <div className="cs-image-group">
                    {/*Icon Circle Group*/}
                    {/* <picture className="cs-icon-wrapper">
                        <img className="cs-icon" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Hero/mop-white.svg" alt="icon" width="52" height="52" aria-hidden="true" />
                    </picture> */}

                    {/* SVG Paint Splash*/}
                    {/* <img className="cs-splash" decoding="async" src="https://csimg.nyc3.digitaloceanspaces.com/Hero/paint-splash.svg" alt="icon" width="792" height="616" aria-hidden="true" /> */}

                    {/* Maid Picture */}
                    {/* To make your own crops of people, use this tool to remove the background of images https://www.remove.bg/ */}
                    <picture className="cs-person">
                        <source media="(max-width: 600px)" srcSet="./mockUp2.png" />
                        <source media="(min-width: 601px)" srcSet="./mockUp2.png" />
                        <img aria-hidden="true" loading="lazy" decoding="async" src="./mockUp2.png" alt="maid" width="831" height="674" />
                    </picture>
                </div>
            </div>
        </section>
    );
};

export default Hero;