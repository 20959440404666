import React from 'react';
import "./SideBySide.css";

const items = [
    {
        title: "100% Secure",
        text: "Our sites are just static HTML and CSS code, meaning there’s literally nothing that can be hacked."
    },
    {
        title: "Custom Designed",
        text: "Our designs are made by an in-house design team, which allows us to make anything we want."
    },
    {
        title: "Money Back Guarantee",
        text: "If we can’t design something you like, you get your money back and the contract is cancelled. We stand by our work."
    },
    {
        title: "100 Pagespeed Scores",
        text: "Our sites have zero bloat, zero waste, and built with purpose so we get perfect 98-100/100 speed scores with Google."
    },
    {
        title: "Unmatched Support",
        text: "Call or text us anytime, no phone trees or robots. When you call us you get me - the owner and developer."
    },
    {
        title: "We Know SEO",
        text: "No snake oil, no tricks, no lies. We explain very clearly what SEO is, how it works, and what we can do to get you ranking."
    },
    // ... add the rest of the items here
];

const iconUrl = "./check.svg";


const SideBySide = () => {
    return (
        <section id="sbs-1362">
            <div className="cs-container">
                <div className="cs-image-group">
                    <picture className="cs-background">
                        {/*Mobile Image*/}
                        <source media="(max-width: 600px)" srcSet="./Alex.png" />
                        {/*Tablet and above Image*/}
                        <source media="(min-width: 601px)" srcSet="./Alex.png" />
                        <img loading="lazy" decoding="async" src="./Alex.png" alt="people" width="605" height="690" />
                    </picture>
                    <div className="cs-box">
                        <div className="cs-flex">
                            <span className="cs-name">Alex Walker</span>
                            <span className="cs-job">Owner, Developer</span>
                        </div>
                        <img className="cs-box-icon" loading="lazy" decoding="async" src="./wavesLight.svg" alt="people" width="60" height="60" />
                    </div>
                </div>
                <div className="cs-content">
                    <span className="cs-topper">What We Offer</span>
                    <h2 className="cs-title">Websites Starting At $0 Down And $175 Per Month</h2>
                    <p className="cs-text">
                        We offer $0 down for a standard 5 page small business website. If you need more than that then we have to do custom pricing based on the scope of work, number of additional pages, and time involved. <strong>12 month minimum contract.</strong> Includes design, development, hosting, unlimited edits, 24/7 support, and lifetime updates.
                    </p>
                    <ul className="cs-card-group">
                        {items.map((item, index) => (
                            <li key={index} className="cs-item">
                                <h3 className="cs-h3">
                                    <img className="cs-h3-icon" aria-hidden="true" loading="lazy" decoding="async" src={iconUrl} alt="icon" width="32" height="32" />
                                    {item.title}
                                </h3>
                                <p className="cs-item-text">
                                    {item.text}
                                </p>
                            </li>
                        ))}
                    </ul>
                    <a href="/" className="cs-button-solid">Schedule a Call</a>
                </div>
            </div>
            <div className="cs-bubbles" aria-hidden="true"></div>
        </section>
    );
};

export default SideBySide;