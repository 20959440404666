import React from 'react';
import "./Footer.css";

const email = "alexwalker293@gmail.com";

const contactInfo = [
    // { href: "tel:555-497-1900", icon: "https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fphone-grey.svg", text: "555-497-1900" },
    { href: `mailto:${email}`, icon: "https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fmessage-grey.svg", text: email },
    { href: "#", target: "_blank", icon: "https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fclock-grey.svg", text: "24/7" },
    // { href: "#", target: "_blank", icon: "https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons%2Fpin-grey.svg", text: "555 Olive Crt, Montclair, CA" },
];

const navLinks = [
    { href: "/", text: "Home" },
    // { href: "/about", text: "About" },
    { href: "/pricing", text: "Pricing" },
    // { href: "/faq", text: "FAQ" },
    // { href: "/contact", text: "Contact" },
];

const Footer = () => {
    return (
        <footer id="cs-footer-840">
            <div className="cs-container">
                <div className="cs-logo-group">
                    <a aria-label="go back to home" className="cs-logo" href="/">
                        <img className="cs-logo-img" aria-hidden="true" loading="lazy" decoding="async" src="./NewFontLakeGroveHorizLogoLight.svg" alt="logo" />
                    </a>
                    <p className="cs-text">
                    We believe small businesses deserve better. Just because you’re small, doesn’t mean your site needs to be. Let us make you something amazing.
                    </p>
                    {/* <div className="cs-social">
                        <button>hi</button>>
                    </div> */}
                </div>
                <div className="cs-ul-wrapper">
                    <span className="cs-header">Quick Links</span>
                    <ul className="cs-ul cs-ul-1">
                        {navLinks.map((link, index) => (
                            <li key={index} className="cs-li">
                                <a className="cs-link" href={link.href}>{link.text}</a>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="cs-ul-wrapper">
                    <span className="cs-header">Contact Information</span>
                    <ul className="cs-ul">
                    {contactInfo.map((info, index) => (
                        <li key={index} className="cs-li">
                            <a className="cs-link" href={info.href} target={info.target}>
                                <img className="cs-icon" aria-hidden="true" loading="lazy" decoding="async" src={info.icon} alt="icon" width="20" height="20" />
                                {info.text}
                            </a>
                        </li>
                    ))}
                </ul>
                </div>
            </div>
            <div className="cs-bottom">
                <span className="cs-credit">
                    © Copyright 2024 <a href="/" className="cs-credit-link" target="_blank">Lake Grove Web Designs</a>
                </span>
                {/* <div className="cs-bottom-links">
                    <a href="#" className="cs-bottom-link">Privacy Policy</a>
                    <a href="#" className="cs-bottom-link">Terms Of Use</a>
                </div> */}
            </div>
        </footer>
    );
};

export default Footer;