import React from 'react';

const Contact = () => {
    return (
        <section id="ContactForm-721">
            <div className="cs-container">
                <picture className="cs-picture">
                    <source media="(max-width: 600px)" srcSet="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FPeople%2Fruuner1-m.jpg" />
                    <source media="(min-width: 601px)" srcSet="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FPeople%2Frunner1.jpg" />
                    <img decoding="async" src="https://csimg.nyc3.cdn.digitaloceanspaces.com/Images%2FPeople%2Frunner1.jpg" alt="runner" width="610" height="425" aria-hidden="true" />
                </picture>
                <div className="cs-content">
                    <span className="cs-topper">Contact Us</span>
                    <h2 className="cs-title">Get in Touch</h2>
                    <p className="cs-text">
                        In consequat tincidunt turpis sit amet imperdiet. Praesent Class officelan nonatoureanor mauris laoreet, iaculis libero quis. 
                    </p>
                    {/*Form*/}
                    <form className="cs-form" id="cs-form-718-721" name="Contact Form" method="post">
                        <label className="cs-label">
                            Name
                            <input className="cs-input" required type="text" id="name-718-721" name="name" placeholder="Name" />
                        </label>
                        <label className="cs-label cs-email">
                            Email
                            <input className="cs-input" required type="text" id="email-718-721" name="email" placeholder="Email" />
                        </label>
                        <label className="cs-label cs-phone">
                            Phone
                            <input className="cs-input" required type="text" id="phone-718-721" name="phone" placeholder="Phone" />
                        </label>
                        <label className="cs-label">
                            Message
                            <textarea className="cs-input cs-textarea" required name="Message" id="message-718-721" placeholder="Write message..."></textarea>
                        </label>
                        <button className="cs-button-solid cs-submit" type="submit">Make an Appointment</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default Contact;