import React, { useEffect, useState } from 'react';
import faqData from './faqData.json';
import "./FAQ.css"

const buttonData = [
    { filter: 'one', text: 'PRICING & PAYMENTS' },
    { filter: 'two', text: 'PLANS' },
    { filter: 'three', text: 'SEO' },
    { filter: 'four', text: 'WEBSITES' },
];

const FAQ = () => {
    const [activeFilter, setActiveFilter] = useState('one');
    

      const handleClick = (filter) => {
        setActiveFilter(filter);
        filterFAQs(filter);
    };

    const filterFAQs = (filter) => {
        const faqGroups = document.querySelectorAll('.cs-faq-group');
        faqGroups.forEach(group => {
            if (group.dataset.category === filter || filter === 'all') {
                group.classList.remove('cs-hidden');
                group.classList.add('cs-active');
            } else {
                group.classList.add('cs-hidden');
                group.classList.remove('cs-active');
            }
        });
    }

    useEffect(() => {
        filterFAQs(activeFilter);
        const faqItems = Array.from(document.querySelectorAll('.cs-faq-item'));
        const clickHandlers = [];
    
        faqItems.forEach(item => {
            const clickHandler = () => {
                item.classList.toggle('active');
            };
            item.addEventListener('click', clickHandler);
            clickHandlers.push(clickHandler);
        });
    
        return () => {
            faqItems.forEach((item, index) => {
                item.removeEventListener('click', clickHandlers[index]);
                item.classList.remove('active');
            });
        };
    }, [activeFilter]);


    return (

<section id="faq-2066">
    <div className="cs-container">
        <div className="cs-content">
            <span className="cs-topper">Frequently Asked Questions</span>
            <h2 className="cs-title">Frequently Asked Questions</h2>
            <p className="cs-text">
                Orci eu lobortis elementum nibh tellus. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Luctus accumsan tortor posuere ac ut consequat semper. Et pharetra pharetra massa massa ultricies mi quis.
            </p>
        </div>
        <div className="cs-flex-group">
                <div className="cs-button-group">
                {buttonData.map((button, index) => (
                    <button 
                        key={index} 
                        className={`cs-option ${activeFilter === button.filter ? 'cs-active' : ''}`} 
                        data-filter={button.filter} 
                        onClick={() => handleClick(button.filter)}
                    >
                        {button.text}
                    </button>
                ))}
            </div>
            <div className="cs-wrapper">
            <ul className="cs-faq-group" data-category="one">
            {faqData.dataCategoryOne.map((item, index) => (
                <li key={index} className="cs-faq-item">
                <button className="cs-button">
                    <span className="cs-button-text">
                    {item.question}
                    </span>
                </button>
                <p className="cs-item-p">
                    {item.answer}
                </p>
                </li>
            ))}
            </ul>
             <ul className="cs-faq-group cs-hidden" data-category="two">
            {faqData.dataCategoryTwo.map((item, index) => (
                <li key={index} className="cs-faq-item">
                <button className="cs-button">
                    <span className="cs-button-text">
                    {item.question}
                    </span>
                </button>
                <p className="cs-item-p">
                    {item.answer}
                </p>
                </li>
            ))}
            </ul>
            <ul className="cs-faq-group cs-hidden" data-category="three">
            {faqData.dataCategoryThree.map((item, index) => (
                <li key={index} className="cs-faq-item">
                <button className="cs-button">
                    <span className="cs-button-text">
                    {item.question}
                    </span>
                </button>
                <p className="cs-item-p">
                    {item.answer}
                </p>
                </li>
            ))}
            </ul>
            <ul className="cs-faq-group cs-hidden" data-category="four">
            {faqData.dataCategoryFour.map((item, index) => (
                <li key={index} className="cs-faq-item">
                <button className="cs-button">
                    <span className="cs-button-text">
                    {item.question}
                    </span>
                </button>
                <p className="cs-item-p">
                    {item.answer}
                </p>
                </li>
            ))}
            </ul>
            </div>
        </div>
    </div>
</section>
                                
    );
};

export default FAQ;