import React from 'react';
import "./ContactUs.css"

// import Banner from "./Banner/Banner";
import Contact from "./Contact/Contact";
// import Stats from "./Stats/Stats";
// import Map from "./Map/Map";
// import FAQ from "./FAQ/FAQ";


const ContactUs = () => {
    return (
    <>
        {/* <Banner /> */}
        <Contact />
        {/* <Stats />
        <Map />
        <FAQ /> */}
    </>                        
    );
};

export default ContactUs;