import React from 'react';
import "./Services.css";

// monitor.svg
// paint-brush.svg
// responsive.svg
// smartphone.svg
// speed.svg
// growth.svg 

// - Web Design
//     - We build custom, hand-coded websites for small businesses that are fast, secure, and optimized for SEO. Our sites achieve 98+/100 performance scores on Google’s Core Web Vitals, ensuring higher rankings and faster load times, unlike bloated page builders such as WordPress and Wix.
// - Graphic Design
//     - At VladHlad Media, we create stunning graphic designs that elevate your brand's presence. Our modern, trend-focused designs enhance ad performance and resonate with your target audience, ensuring your marketing efforts stand out in a crowded market.
// - Mobile First Design
// - Fully Responsive
// - Optimized Page Speed
// - SEO Services

const services = [
    {
        icon: "./monitor.svg",
        title: "Web Design",
        text: "We build custom, hand-coded websites for small businesses that are fast, secure, and optimized for SEO."
    },
    {
        icon: "./paint-brush.svg",
        title: "Graphic Design",
        text: "We create stunning graphic designs that elevate your brand's presence. Our modern, trend-focused designs enhance ad performance and resonate with your target audience, ensuring your marketing efforts stand out in a crowded market."
    },
    {
        icon: "./responsive.svg",
        title: "Fully Responsive",
        text: "Your website will fit all mobile screens sizes, tablets, and desktop sizes so visitors can access your site from anywhere and see a beautiful site no matter the screen size."
    },
    {
        icon: "./smartphone.svg",
        title: "Mobile First Design",
        text: "We start building your site for mobile devices first making sure the code is as lean and optimized with no bloated waste and makes the site even more responsive."
    },
    {
        icon: "./speed.svg",
        title: "Optimized Page Speed",
        text: "If your site takes more than 3 seconds to load you can lose up to 50% your traffic because it took too long. Our sites load 1 second or less ensuring everyone that visits your site reaches it."
    },
    {
        icon: "./growth.svg",
        title: "SEO Services",
        text: "We have an in-house SEO specialist who understands local SEO and how to rank in your local market with plenty of case studies to show results and monthly reports."
    },
    // {
    //     icon: "https://csimg.nyc3.cdn.digitaloceanspaces.com/Icons/car2.svg",
    //     title: "Based in the USA",
    //     text: "We do not hire cheap overseas developers. We are mostly based in the USA with team members in Washington, Texas, Pennsylvania, and the UK and work 100% remote from home."
    // },
];

const Services = () => {
    return (
        <section id="services-1064">
            <div className="cs-container">
                <div className="cs-content">
                    <span className="cs-topper">WHAT WE DO</span>
                    <h2 className="cs-title"> NEVER WORRY ABOUT YOUR WEBSITE AGAIN </h2>
                    <p className="cs-text">
                        At Lake Grove Web Designs, we specialize in small business web design and development. Every line of code is written by hand, optimized for speed, security, and SEO, which helps bring in more customers to your site and bring more revenue to your business. We also manage the edits for you and will never leave you high and dry. Our goal is to create long term relationships with our clients and see them grow over time.
                    </p>
                </div>
                <ul className="cs-card-group">
                {services.map((service, index) => (
                    <li key={index} className="cs-item">
                        <picture className="cs-icon-wrapper">
                            <img className="cs-icon" loading="lazy" decoding="async" src={service.icon} alt="car" width="48" height="48" />
                        </picture>
                        <h3 className="cs-h3">{service.title}</h3>
                        <p className="cs-item-text">{service.text}</p>
                    </li>
                ))}
            </ul>
    </div>
</section>
    );
};

export default Services;